//$transition_base: ease-in-out 0.3s;
$main_color: #41aaf5;
$white_color: #fff;
$grey_color: #5f6168;
$black_color: #2e3034;

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  //outline: 1px solid;
}

body {
  background-color: #f7f7f7;
  color: $grey_color;
  margin: 0;
  padding: 0;
  font-family: 'Ysabeau SC', sans-serif;
  font-weight: 400;
  /* Don't forget about fallback font/family */
  font-size: clamp(14px, 5vw, 16px);
  line-height: normal;
  min-height: 100vh;
}

.title-box .upper-heading {
  margin: 0;
  padding: 0;
  padding-block-end: 8px;
  color: $main_color;
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.title-box.title-box--center {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}

.logo {
  display: inline-block;
  height: auto;
}

.logo > img {
  object-fit: contain;
}

img {
  max-width: 100%;
  height: auto;
}

.button {
  display: inline-block;
  border: none;
  margin: 0;
  padding: 10px 24px;
  border-radius: 14px;
  background: $main_color;
  transition: linear .3s;
  color: $white_color;
  font-family: inherit;
  font-size: clamp(16px, 5vw, 20px);
  font-weight: 700;
  text-decoration: none;
  //text-transform: uppercase;
}

.button:hover {
  background-color: lighten($main_color, 5%);
  transition: linear .3s;
}

.main-title {
  margin: 0;
  padding: 0;
}

.first-title {
  margin: 0;
  padding: 0;
  font-size: clamp(30px, 5vw, 48px);
  font-weight: 700;
  color: #14171E;
}

.second-title {
  margin: 0;
  padding: 0;
  font-size: clamp(26px, 5vw, 38px);
  font-weight: 700;
  color: $black_color;
}

.third-title {
  font-size: clamp(18px, 5vw, 24px);
  margin: 0;
  padding: 0;
  font-weight: 700;
  color: $black_color;
}

.main-text {
  color: $grey_color;
}

.text--white,
.upper-heading.text--white {
  color: $white_color;
}

.text--black {
  color: $black_color;
}

.text--main-color {
  color: $main_color;
}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
}

@import 'modules/a11y';
@import 'modules/grid';
@import 'modules/fonts';
@import 'modules/header';
@import 'modules/home/hero';
@import 'modules/home/investment';
@import 'modules/features-list';
@import 'modules/home/market';
@import 'modules/home/get-started';
@import 'modules/home/features';
@import 'modules/home/chart';
@import 'modules/home/pricing';
@import 'modules/home/faq';
@import 'modules/footer';

.main-text--center {
  text-align: center;
}

.map {
  margin-block-end: 32px;
}
