.container,
.container-fluid {
  width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding: 0 15px;
}

.container-flex {
  display: flex;
  width: 100%;
}

.container-flex--sb {
  justify-content: space-between;
}

.container-flex--center {
  justify-content: center;
}

.container-flex--ai-center {
  align-items: center;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 976px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
