.footer {
  background-color: $black_color;
}

.footer__container {
  align-items: flex-start;
  //border-block-start: 1px solid rgb(82, 47, 235);
  padding-block-start: 44px;
  padding-block-end: 40px;
}


.contacts-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.contacts-link {
  text-decoration: none;
  //color: inherit;
}

.contacts-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.contacts-item:not(:last-child) {
  margin-block-end: 12px;
}

.contacts-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.footer__motto {
  max-width: 384px;
  margin: 0;
  //font-weight: 300;
  //font-family: sans-serif;
}

.footer__menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__item:not(:last-child) {
  margin-block-end: 12px;
}

.footer__link {
  //color: inherit;
}

.footer__copy {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  background-color: $black_color;
}

.footer__copy-text {
  color: $main_color;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  margin: 0;
  padding: 0;
}

@media (max-width: 991px) {
  .footer__motto {
    max-width: 250px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .footer__container {
    flex-wrap: wrap;
    gap: 44px;
  }
}

@media (max-width: 575px) {
  .footer__container {
    flex-direction: column;
    gap: 24px;
  }
}
