.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: $white_color;
}

//.upper-header {
//  background-color: #f9f9f9;
//}
//
//.upper-header__container {
//  justify-content: space-between;
//  align-items: center;
//  font-size: 12px;
//}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-block-start: 24px;
  padding-block-end: 24px;
}

.header__menu {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}

.header__menu-link {
  text-decoration: none;
  color: $black_color;
  font-weight: 700;
  font-size: clamp(16px, 5vw, 20px);
}

.header__menu-item--is-active .header__menu-link {
  color: $main_color;
}

.header__actions {
  display: flex;
  gap: 32px;
}

.lang-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.lang-link {
  text-decoration: none;
  font-weight: 700;
  color: $main_color;
  border: 1px solid $main_color;
  padding: 5px 14px;
  border-radius: 14px;
}

.hamburger {
  padding: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  display: none;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: $black_color;
}

.hamburger-box {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 24px;
  height: 2px;
  background-color: $black_color;
  border-radius: 2px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}

.hamburger--spring .hamburger-inner {
  top: 8px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 5px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
  transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 10px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
  transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
  transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
  transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

@media (max-width: 991px) {
  .hamburger {
    display: block;
  }

  .header__nav,
  .header__actions {
    position: absolute;
    top: 84px;
    left: 0;
    background-color: $white_color;
    width: 100%;
    padding: 14px 0;
    justify-content: center;
    text-align: center;
    box-shadow: 0 15px 15px 0 rgba(0,0,0,.1);
    transform-origin: 0 0;
    visibility: hidden;
    transform: scaleX(0);
    transition: linear .3s;
  }

  .header__actions {
    //position: static;
    top: 420px;
    padding-block-start: 24px;
    padding-block-end: 32px;
  }

  .header__nav.opened,
  .header__actions.opened {
    transform-origin: 0 0;
    visibility: visible;
    transform: scaleX(1);
    transition: linear .3s;
  }

  .header__menu {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .upper-header__container {
    gap: 70px;
    font-size: 10px;
  }
}
