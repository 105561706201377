@font-face {
  font-family: "Ysabeau SC";
  font-display: swap;
  src: url("../../assets/fonts/YsabeauSC-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ysabeau SC";
  font-display: swap;
  src: url("../../assets/fonts/YsabeauSC-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

::-webkit-input-placeholder {
  font-family: 'Ysabeau SC';
}

::-moz-placeholder {
  font-family: 'Ysabeau SC';
}

:-ms-input-placeholder {
  font-family: 'Ysabeau SC';
}

:-moz-placeholder {
  font-family: 'Ysabeau SC';
}
