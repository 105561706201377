.investment {
  padding: 85px 0;
}

.investment--bg-filled {
  background: linear-gradient(180deg, rgba(24, 27, 31, 0.00) 80.48%, #181B1F 98.12%),
              linear-gradient(180deg, #F7F7F7 14.19%, rgba(247, 247, 247, 0.00) 74.35%),
              url(../../assets/img/investment-banner-bg.png) lightgray 50% / cover no-repeat;
  background-size: cover;
  min-height: 845px;
}

.investment__text {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-block-start: 16px;
}
