.chart {
  padding: 85px 0;
}

.chart .title-box {
  max-width: 600px;
  margin: 0 auto;
}

.chart__container {
  margin-block-start: 24px;
  height: 70vh;
}