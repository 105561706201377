.hero-banner {
  flex-shrink: 0;
  background-image: url(../../assets/img/hero-banner-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 633px;
  padding-block-start: 180px;
}

.hero-banner--about {
  background-image: url(../../assets/img/hero-banner-about-bg.png);
}

.hero-banner--trades {
  background-image: url(../../assets/img/hero-banner-trades-bg.png);
}

.hero-banner--investment {
  background-image: url(../../assets/img/hero-banner-investment-bg.png);
}

.hero-banner--contact {
  background-image: url(../../assets/img/hero-banner-contact-bg.png);
}

.hero-banner__content {
  display: flex;
  flex-direction: column;
  max-width: 620px;
  gap: 16px;
}

.hero-banner__content .button {
  margin-block-start: 8px;
}

@media (max-width: 991px) {
  .hero-banner {
    //padding: 52px 0;
  }

  .hero-banner__content {
    flex: 2;
  }

  .hero-banner__img {
    flex: 1;
  }
}

@media (max-width: 767px) {
  .hero-banner .container {
    flex-wrap: wrap;
  }
  
  .hero-banner__content,
  .hero-banner__img {
    flex: unset;
    width: 100%;
    max-width: unset;
    align-items: center;
  }

  .hero-banner__img {
    width: 100%;
  }
}
