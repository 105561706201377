.features-list {
  padding: 0;
  margin: 0;
  padding-block-start: 24px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.features-list--about,
.features-list--trades {
  flex-wrap: wrap;
}

.features-list__item {
  flex: 1;
  border-radius: 14px;
  background: $white_color;
  box-shadow: 0px 1px 14px 0px rgba(46, 48, 52, 0.05);
  padding: 20px;
  padding-block-end: 34px;
  text-align: start;
  transition: linear .35s;
}

.features-list--about .features-list__item {
  flex: unset;
  width: 47%;
}

.features-list--trades .features-list__item {
  flex: unset;
  width: 100%;
  max-width: 370px;
}

.features-list--black .features-list__item {
  background: rgba(46, 48, 52, 0.70);
  padding-block-end: 20px;
}

.features-list__circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  //width: 86px;
  //height: 86px;
  //border-radius: 60px;
  //background: #151219;
  //box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.55) inset, 0px 0px 15px 0px rgba(36, 117, 230, 0.75);
  //transition: linear .35s;
}

.features-list__circle img {
  width: 36px;
  height: 36px;
}

.features-list__item-title {
  margin-block-start: 24px;
  margin-block-end: 12px;
  display: flex;
  align-items: center;
  gap: 14px;
}

.features-list--black .features-list__item-title,
.features-list--trades .features-list__item-title {
  margin-block-start: 0;
}

//.features-list--investment .features-list__item {
//  text-align: start;
//}
//
//.features-list--investment .features-list__circle {
//  background: rgb(82, 47, 235);
//}
//
//.features-list--investment .features-list__item:hover .features-list__circle {
//  transform: none;
//}
//
//.features-list--investment .features-list__item:hover .features-list__circle img:nth-child(1),
//.features-list--investment .features-list__item:hover .features-list__circle img:nth-child(2) {
//  display: block;
//}

@media (max-width: 991px) {
  .features-list {
    flex-wrap: wrap;
    justify-content: center;
  }

  .features-list__item {
    flex: unset;
    width: 45%;
  }

  .features-list--investment .features-list__item {
    text-align: center;
  }

  .features-list--about .features-list__item {
    width: 45%;
  }
}

@media (max-width: 575px) {
  .features-list__item {
    width: 75%;
  }

  .features-list--about .features-list__item {
    width: 75%;
  }
}
