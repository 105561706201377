html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: #f7f7f7;
  color: #5f6168;
  margin: 0;
  padding: 0;
  font-family: "Ysabeau SC", sans-serif;
  font-weight: 400;
  /* Don't forget about fallback font/family */
  font-size: clamp(14px, 5vw, 16px);
  line-height: normal;
  min-height: 100vh;
}

.title-box .upper-heading {
  margin: 0;
  padding: 0;
  padding-block-end: 8px;
  color: #41aaf5;
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.title-box.title-box--center {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}

.logo {
  display: inline-block;
  height: auto;
}

.logo > img {
  object-fit: contain;
}

img {
  max-width: 100%;
  height: auto;
}

.button {
  display: inline-block;
  border: none;
  margin: 0;
  padding: 10px 24px;
  border-radius: 14px;
  background: #41aaf5;
  transition: linear 0.3s;
  color: #fff;
  font-family: inherit;
  font-size: clamp(16px, 5vw, 20px);
  font-weight: 700;
  text-decoration: none;
}

.button:hover {
  background-color: #59b5f6;
  transition: linear 0.3s;
}

.main-title {
  margin: 0;
  padding: 0;
}

.first-title {
  margin: 0;
  padding: 0;
  font-size: clamp(30px, 5vw, 48px);
  font-weight: 700;
  color: #14171E;
}

.second-title {
  margin: 0;
  padding: 0;
  font-size: clamp(26px, 5vw, 38px);
  font-weight: 700;
  color: #2e3034;
}

.third-title {
  font-size: clamp(18px, 5vw, 24px);
  margin: 0;
  padding: 0;
  font-weight: 700;
  color: #2e3034;
}

.main-text {
  color: #5f6168;
}

.text--white,
.upper-heading.text--white {
  color: #fff;
}

.text--black {
  color: #2e3034;
}

.text--main-color {
  color: #41aaf5;
}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

.container,
.container-fluid {
  width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding: 0 15px;
}

.container-flex {
  display: flex;
  width: 100%;
}

.container-flex--sb {
  justify-content: space-between;
}

.container-flex--center {
  justify-content: center;
}

.container-flex--ai-center {
  align-items: center;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 976px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@font-face {
  font-family: "Ysabeau SC";
  font-display: swap;
  src: url("../../assets/fonts/YsabeauSC-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ysabeau SC";
  font-display: swap;
  src: url("../../assets/fonts/YsabeauSC-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
::-webkit-input-placeholder {
  font-family: "Ysabeau SC";
}

::-moz-placeholder {
  font-family: "Ysabeau SC";
}

:-ms-input-placeholder {
  font-family: "Ysabeau SC";
}

:-moz-placeholder {
  font-family: "Ysabeau SC";
}

.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-block-start: 24px;
  padding-block-end: 24px;
}

.header__menu {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}

.header__menu-link {
  text-decoration: none;
  color: #2e3034;
  font-weight: 700;
  font-size: clamp(16px, 5vw, 20px);
}

.header__menu-item--is-active .header__menu-link {
  color: #41aaf5;
}

.header__actions {
  display: flex;
  gap: 32px;
}

.lang-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.lang-link {
  text-decoration: none;
  font-weight: 700;
  color: #41aaf5;
  border: 1px solid #41aaf5;
  padding: 5px 14px;
  border-radius: 14px;
}

.hamburger {
  padding: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  display: none;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #2e3034;
}

.hamburger-box {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 24px;
  height: 2px;
  background-color: #2e3034;
  border-radius: 2px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger--spring .hamburger-inner {
  top: 8px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 5px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 10px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

@media (max-width: 991px) {
  .hamburger {
    display: block;
  }

  .header__nav,
.header__actions {
    position: absolute;
    top: 84px;
    left: 0;
    background-color: #fff;
    width: 100%;
    padding: 14px 0;
    justify-content: center;
    text-align: center;
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.1);
    transform-origin: 0 0;
    visibility: hidden;
    transform: scaleX(0);
    transition: linear 0.3s;
  }

  .header__actions {
    top: 420px;
    padding-block-start: 24px;
    padding-block-end: 32px;
  }

  .header__nav.opened,
.header__actions.opened {
    transform-origin: 0 0;
    visibility: visible;
    transform: scaleX(1);
    transition: linear 0.3s;
  }

  .header__menu {
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .upper-header__container {
    gap: 70px;
    font-size: 10px;
  }
}
.hero-banner {
  flex-shrink: 0;
  background-image: url(../../assets/img/hero-banner-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 633px;
  padding-block-start: 180px;
}

.hero-banner--about {
  background-image: url(../../assets/img/hero-banner-about-bg.png);
}

.hero-banner--trades {
  background-image: url(../../assets/img/hero-banner-trades-bg.png);
}

.hero-banner--investment {
  background-image: url(../../assets/img/hero-banner-investment-bg.png);
}

.hero-banner--contact {
  background-image: url(../../assets/img/hero-banner-contact-bg.png);
}

.hero-banner__content {
  display: flex;
  flex-direction: column;
  max-width: 620px;
  gap: 16px;
}

.hero-banner__content .button {
  margin-block-start: 8px;
}

@media (max-width: 991px) {
  .hero-banner__content {
    flex: 2;
  }

  .hero-banner__img {
    flex: 1;
  }
}
@media (max-width: 767px) {
  .hero-banner .container {
    flex-wrap: wrap;
  }

  .hero-banner__content,
.hero-banner__img {
    flex: unset;
    width: 100%;
    max-width: unset;
    align-items: center;
  }

  .hero-banner__img {
    width: 100%;
  }
}
.investment {
  padding: 85px 0;
}

.investment--bg-filled {
  background: linear-gradient(180deg, rgba(24, 27, 31, 0) 80.48%, #181B1F 98.12%), linear-gradient(180deg, #F7F7F7 14.19%, rgba(247, 247, 247, 0) 74.35%), url(../../assets/img/investment-banner-bg.png) lightgray 50%/cover no-repeat;
  background-size: cover;
  min-height: 845px;
}

.investment__text {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  margin-block-start: 16px;
}

.features-list {
  padding: 0;
  margin: 0;
  padding-block-start: 24px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.features-list--about,
.features-list--trades {
  flex-wrap: wrap;
}

.features-list__item {
  flex: 1;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 1px 14px 0px rgba(46, 48, 52, 0.05);
  padding: 20px;
  padding-block-end: 34px;
  text-align: start;
  transition: linear 0.35s;
}

.features-list--about .features-list__item {
  flex: unset;
  width: 47%;
}

.features-list--trades .features-list__item {
  flex: unset;
  width: 100%;
  max-width: 370px;
}

.features-list--black .features-list__item {
  background: rgba(46, 48, 52, 0.7);
  padding-block-end: 20px;
}

.features-list__circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.features-list__circle img {
  width: 36px;
  height: 36px;
}

.features-list__item-title {
  margin-block-start: 24px;
  margin-block-end: 12px;
  display: flex;
  align-items: center;
  gap: 14px;
}

.features-list--black .features-list__item-title,
.features-list--trades .features-list__item-title {
  margin-block-start: 0;
}

@media (max-width: 991px) {
  .features-list {
    flex-wrap: wrap;
    justify-content: center;
  }

  .features-list__item {
    flex: unset;
    width: 45%;
  }

  .features-list--investment .features-list__item {
    text-align: center;
  }

  .features-list--about .features-list__item {
    width: 45%;
  }
}
@media (max-width: 575px) {
  .features-list__item {
    width: 75%;
  }

  .features-list--about .features-list__item {
    width: 75%;
  }
}
.market {
  padding: 92px 0;
}

.market--bg-filled {
  background: linear-gradient(180deg, rgba(247, 247, 247, 0) 50.09%, #F7F7F7 97.87%), linear-gradient(90deg, rgba(46, 48, 52, 0) 45.42%, rgba(46, 48, 52, 0.7) 100%), linear-gradient(301deg, rgba(247, 247, 247, 0) 80.03%, #F7F7F7 91.73%), linear-gradient(241deg, rgba(247, 247, 247, 0) 54.4%, #F7F7F7 81.7%), linear-gradient(270deg, rgba(247, 247, 247, 0) 33.22%, #F7F7F7 76.96%), url(../../assets/img/market-banner-bg.png) lightgray 50%/cover no-repeat;
  background-size: cover;
}

.market--bg-filled-city {
  background-image: url(../../assets/img/market-banner-bg-2.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.market--bg-filled--about {
  background-image: url(../../assets/img/market-banner-bg-3.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.market--trade-page {
  background-image: url(../../assets/img/market-banner-bg-4.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 700px;
}

.market .container {
  align-items: center;
  gap: 50px;
}

.market__content--fixed {
  max-width: 585px;
  background: linear-gradient(90deg, #F7F7F7 0%, rgba(247, 247, 247, 0) 100.05%);
  padding: 70px 0;
}

.market__content,
.market__img {
  flex: 1;
}

.market__list {
  margin: 0;
  padding: 0;
  padding-block-start: 28px;
  list-style: none;
  display: flex;
  column-gap: 68px;
}

.market__item {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.market__item:not(:last-child)::after {
  content: "";
  display: block;
  width: 1px;
  height: 35px;
  background-color: #383c42;
  position: absolute;
  inset-inline-end: -34.5px;
}

@media (max-width: 991px) {
  .market {
    padding: 44px 0;
  }
}
@media (max-width: 767px) {
  .market--bg-filled {
    background: linear-gradient(180deg, rgba(247, 247, 247, 0.5) 50.09%, #f7f7f7 97.87%), linear-gradient(90deg, rgba(46, 48, 52, 0) 45.42%, rgba(46, 48, 52, 0.7)), linear-gradient(301deg, rgba(247, 247, 247, 0.5) 80.03%, #f7f7f7 91.73%), linear-gradient(241deg, rgba(247, 247, 247, 0) 54.4%, #f7f7f7 81.7%), linear-gradient(270deg, rgba(247, 247, 247, 0) 33.22%, #f7f7f7 76.96%), url(../../assets/img/market-banner-bg.png) #d3d3d3 50%/cover no-repeat;
    background-size: cover;
    background-position: 40%;
  }

  .market .container {
    flex-wrap: wrap;
  }

  .market__content,
.market__img {
    flex: unset;
  }

  .market__img {
    width: 100%;
    order: 1;
  }
}
.get-started {
  background-image: url(../../assets/img/hero-banner-bg.png);
  background-size: cover;
  padding: 24px 0;
  text-align: center;
}

.get-started__button {
  margin-block-start: 24px;
}

.features {
  padding-block-start: 85px;
  padding-block-end: 63px;
  background: linear-gradient(180deg, #242429 0%, rgba(36, 36, 41, 0) 22.04%, #242429 100%), url(../../assets/img/features-bg.png) lightgray 50%/cover no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
}

.features .title-box {
  max-width: 600px;
  margin: 0 auto;
}

.features__list {
  margin: 0;
  padding: 0;
  padding-block-start: 36px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.features__item:nth-child(2) {
  grid-row: 2;
}

.features__item:nth-child(3) {
  grid-row: 3;
}

.features__item--image {
  grid-column: 2/3;
  grid-row: 1/4;
  width: 500px;
}

.features__item {
  display: flex;
  column-gap: 24px;
}

.features__item-icon {
  display: inline-block;
  width: 68px;
  height: 68px;
}

.features__item-icon img {
  width: 100%;
  height: 100%;
}

.features__item-texts {
  width: 190px;
}

.features__item:nth-child(5),
.features__item:nth-child(6),
.features__item:nth-child(7) {
  text-align: right;
  justify-self: right;
}

@media (max-width: 991px) {
  .features {
    background: linear-gradient(180deg, #242429, rgba(36, 36, 41, 0) 100%, rgba(36, 36, 41, 0) 80%, #242429), linear-gradient(0deg, rgba(36, 36, 41, 0.7), rgba(36, 36, 41, 0.9)), url(../../assets/img/features-bg.png) #d3d3d3 50%/cover no-repeat;
  }

  .features__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .features__item--image {
    display: none;
  }
}
@media (max-width: 700px) {
  .features__list {
    grid-template-columns: 1fr;
  }

  .features__item:nth-child(5),
.features__item:nth-child(6),
.features__item:nth-child(7) {
    text-align: left;
    justify-self: left;
  }

  .features__item:nth-child(5) .features__item-icon,
.features__item:nth-child(6) .features__item-icon,
.features__item:nth-child(7) .features__item-icon {
    order: -1;
  }

  .features__item-texts {
    width: 70%;
  }
}
.chart {
  padding: 85px 0;
}

.chart .title-box {
  max-width: 600px;
  margin: 0 auto;
}

.chart__container {
  margin-block-start: 24px;
  height: 70vh;
}

.pricing {
  padding-block-start: 45px;
  padding-block-end: 85px;
  flex-shrink: 0;
  background: linear-gradient(180deg, #242429 0%, rgba(36, 36, 41, 0) 20%, rgba(36, 36, 41, 0) 80%, #242429 100%), linear-gradient(0deg, rgba(36, 36, 41, 0.7) 0%, rgba(36, 36, 41, 0.7) 100%), url(../../assets/img/hero-banner-bg-price.png) lightgray 50%/cover no-repeat;
}

.pricing__list {
  margin: 0 auto;
  padding: 0;
  padding-block-start: 24px;
  list-style: none;
  display: flex;
  gap: 24px;
  max-width: 981px;
}

.pricing__item {
  border-radius: 4px;
  border: 1px solid #14171E;
  box-shadow: 0px 5px 12px 0px rgba(20, 23, 30, 0.3);
  background-color: transparent;
  padding-block-start: 0;
  flex: 1;
  position: relative;
  transition: linear 0.3s;
  padding-block-end: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  border-radius: 14px;
  border: 1px solid #41aaf5;
  box-shadow: 0px 5px 12px 0px rgba(20, 23, 30, 0.7);
}

.pricing__item:hover {
  transform: scale(1.01);
  transition: linear 0.3s;
}

.pricing__item:nth-child(2) {
  position: relative;
  background: linear-gradient(0deg, rgba(36, 36, 41, 0.9) 0%, rgba(36, 36, 41, 0.9) 100%), url(../../assets/img/pricing-card.png) lightgray 50%/cover no-repeat;
}

.pricing__item-title {
  width: 100.6%;
  inset-inline-start: -1px;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  position: relative;
  padding: 20px;
  border-radius: 14px 14px 0px 0px;
  border-top: 1px solid #41aaf5;
  border-right: 1px solid #41aaf5;
  border-left: 1px solid #41aaf5;
  background: linear-gradient(180deg, #2475E6 0%, #242429 100%);
}

.pricing__item-title-price {
  opacity: 0.7;
  font-weight: 400;
}

.pricing__price-list {
  margin: 0;
  padding: 0 20px;
  list-style: none;
}

.pricing__price-item {
  display: flex;
  column-gap: 8px;
  align-items: center;
  margin-block-end: 20px;
}

.pricing__price-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}

.pricing-button {
  text-align: center;
  width: 90%;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .pricing__list {
    flex-wrap: wrap;
    justify-content: center;
  }

  .pricing__item {
    width: 49%;
    flex: unset;
  }

  .pricing__item:nth-child(2) {
    inset-block-start: 0;
  }
}
@media (max-width: 767px) {
  .pricing__item {
    width: 59%;
  }
}
@media (max-width: 576px) {
  .pricing__item {
    width: 85%;
  }
}
.faq {
  padding: 50px;
}

.faq .container {
  gap: 24px;
}

.faq__content {
  flex: 1;
}

.faq__container {
  flex: 2;
}

.faq__container {
  position: relative;
}

.faq__container::before {
  position: absolute;
  left: -450px;
  z-index: -1;
  top: -350px;
}

.accordion {
  width: 100%;
}

.accordion__container {
  transition: linear 0.3s;
  border-radius: 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  background: #2e3034;
  margin-block-end: 12px;
}

.accordion__question {
  appearance: none;
  list-style: none;
  cursor: pointer;
  padding: 24px;
  padding-right: 100px;
  position: relative;
  font-size: 20px;
  font-weight: 700;
}

.accordion__question::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-image: url(../img/ic-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  transition: linear 0.3s;
}

.accordion__container[open] .accordion__question::after {
  transition: linear 0.3s;
  transform: translateY(-50%) rotate(0deg);
}

.accordion__answer {
  margin: 0;
  padding: 0 24px;
  padding-block-end: 24px;
}

@media (max-width: 991px) {
  .faq .container {
    flex-wrap: wrap;
  }

  .faq__content,
.faq__container {
    flex: unset;
    width: 100%;
  }
}
.footer {
  background-color: #2e3034;
}

.footer__container {
  align-items: flex-start;
  padding-block-start: 44px;
  padding-block-end: 40px;
}

.contacts-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.contacts-link {
  text-decoration: none;
}

.contacts-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.contacts-item:not(:last-child) {
  margin-block-end: 12px;
}

.contacts-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.footer__motto {
  max-width: 384px;
  margin: 0;
}

.footer__menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__item:not(:last-child) {
  margin-block-end: 12px;
}

.footer__copy {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  background-color: #2e3034;
}

.footer__copy-text {
  color: #41aaf5;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  margin: 0;
  padding: 0;
}

@media (max-width: 991px) {
  .footer__motto {
    max-width: 250px;
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .footer__container {
    flex-wrap: wrap;
    gap: 44px;
  }
}
@media (max-width: 575px) {
  .footer__container {
    flex-direction: column;
    gap: 24px;
  }
}
.main-text--center {
  text-align: center;
}

.map {
  margin-block-end: 32px;
}