.features {
  padding-block-start: 85px;
  padding-block-end: 63px;
  //background-image: url(../../assets/img/features-bg.png);
  background: linear-gradient(180deg, #242429 0%, rgba(36, 36, 41, 0.0) 22.04%, #242429 100%),
  url(../../assets/img/features-bg.png) lightgray 50% / cover no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
}

.features .title-box {
  max-width: 600px;
  margin: 0 auto;
}

.features__list {
  margin: 0;
  padding: 0;
  padding-block-start: 36px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.features__item:nth-child(2) {
  grid-row: 2;
}

.features__item:nth-child(3) {
  grid-row: 3;
}

.features__item--image {
  grid-column: 2/3;
  grid-row: 1/4;
  width: 500px;
}

.features__item {
  display: flex;
  column-gap: 24px;
}

.features__item-icon {
  display: inline-block;
  width: 68px;
  height: 68px;
}

.features__item-icon img {
  width: 100%;
  height: 100%;
}

.features__item-texts {
  width: 190px;
}

.features__item:nth-child(5),
.features__item:nth-child(6),
.features__item:nth-child(7) {
  text-align: right;
  justify-self: right;
}

@media (max-width: 991px) {
  .features {
    background: linear-gradient(180deg,#242429,rgba(36,36,41,0) 100%,rgba(36,36,41,0) 80%,#242429),
                linear-gradient(0deg,rgba(36,36,41,.7),rgba(36,36,41,.9)),
                url(../../assets/img/features-bg.png) #d3d3d3 50%/cover no-repeat;
  }

  .features__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .features__item--image {
    display: none;
  }
}

@media (max-width: 700px) {
  .features__list {
    grid-template-columns: 1fr;
  }

  .features__item:nth-child(5),
  .features__item:nth-child(6),
  .features__item:nth-child(7) {
    text-align: left;
    justify-self: left;
  }

  .features__item:nth-child(5) .features__item-icon,
  .features__item:nth-child(6) .features__item-icon,
  .features__item:nth-child(7) .features__item-icon {
    order: -1;
  }

  .features__item-texts {
    width: 70%;
  }
}
