.faq {
  padding: 50px;
}

.faq .container {
  gap: 24px;
}

.faq__content {
  flex: 1;
}

.faq__container {
  flex: 2;
}

.faq__container {
  position: relative;
}

.faq__container::before {
  position: absolute;
  left: -450px;
  z-index: -1;
  top: -350px;
}

.accordion {
  width: 100%;
}

.accordion__container {
  transition: linear .3s;
  border-radius: 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  background: $black_color;
  margin-block-end: 12px;
}

.accordion__question {
  appearance: none;
  list-style: none;
  cursor: pointer;
  padding: 24px;
  padding-right: 100px;
  position: relative;
  //color: rgb(56, 60, 66);
  font-size: 20px;
  font-weight: 700;
}

.accordion__question::after {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  background-image: url(../img/ic-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  transition: linear .3s;
}

.accordion__container[open] .accordion__question::after {
  transition: linear .3s;
  transform: translateY(-50%) rotate(0deg);
}

.accordion__answer {
  margin: 0;
  padding: 0 24px;
  padding-block-end: 24px;
}

//MEDIA
@media (max-width: 991px) {
  .faq .container {
    flex-wrap: wrap;
  }

  .faq__content,
  .faq__container {
    flex: unset;
    width: 100%;
  }
}
