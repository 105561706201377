.market {
  padding: 92px 0;
}

.market--bg-filled {
  background: linear-gradient(180deg, rgba(247, 247, 247, 0.00) 50.09%, #F7F7F7 97.87%),
              linear-gradient(90deg, rgba(46, 48, 52, 0.00) 45.42%, rgba(46, 48, 52, 0.70) 100%),
              linear-gradient(301deg, rgba(247, 247, 247, 0.00) 80.03%, #F7F7F7 91.73%),
              linear-gradient(241deg, rgba(247, 247, 247, 0.00) 54.4%, #F7F7F7 81.7%),
              linear-gradient(270deg, rgba(247, 247, 247, 0.00) 33.22%, #F7F7F7 76.96%),
              url(../../assets/img/market-banner-bg.png) lightgray 50% / cover no-repeat;
  background-size: cover;
}

.market--bg-filled-city {
  background-image: url(../../assets/img/market-banner-bg-2.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.market--bg-filled--about {
  background-image: url(../../assets/img/market-banner-bg-3.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.market--trade-page {
  background-image: url(../../assets/img/market-banner-bg-4.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 700px;
}

.market .container {
  align-items: center;
  gap: 50px;
}

.market__content--fixed {
  max-width: 585px;
  background: linear-gradient(90deg, #F7F7F7 0%, rgba(247, 247, 247, 0.00) 100.05%);
  padding: 70px 0;
}

.market__content,
.market__img {
  flex: 1;
}

.market__list {
  margin: 0;
  padding: 0;
  padding-block-start: 28px;
  list-style: none;
  display: flex;
  column-gap: 68px;
}

.market__item {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.market__item:not(:last-child)::after {
  content: '';
  display: block;
  width: 1px;
  height: 35px;
  background-color: rgb(56, 60, 66);
  position: absolute;
  inset-inline-end: -34.5px;
}

@media (max-width: 991px) {
  .market {
    padding: 44px 0;
  }
}

@media (max-width: 767px) {
  .market--bg-filled {
    background: linear-gradient(180deg,hsla(0,0%,96.9%,.50) 50.09%,#f7f7f7 97.87%),
                linear-gradient(90deg,rgba(46,48,52,0) 45.42%,rgba(46,48,52,.7)),
                linear-gradient(301deg,hsla(0,0%,96.9%,.50) 80.03%,#f7f7f7 91.73%),
                linear-gradient(241deg,hsla(0,0%,96.9%,0) 54.4%,#f7f7f7 81.7%),
                linear-gradient(270deg,hsla(0,0%,96.9%,0) 33.22%,#f7f7f7 76.96%),
                url(../../assets/img/market-banner-bg.png) #d3d3d3 50%/cover no-repeat;
    background-size: cover;
    background-position: 40%;
  }

  .market .container {
    flex-wrap: wrap;
  }

  .market__content,
  .market__img {
    flex: unset;
  }

  .market__img {
    width: 100%;
    order: 1;
  }
}
