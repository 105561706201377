.pricing {
  padding-block-start: 45px;
  padding-block-end: 85px;
  flex-shrink: 0;
  background: linear-gradient(180deg, #242429 0%, rgba(36, 36, 41, 0.00) 20%,
          rgba(36, 36, 41, 0.00) 80%, #242429 100%),
          linear-gradient(0deg, rgba(36, 36, 41, 0.70) 0%, rgba(36, 36, 41, 0.70) 100%),
          url(../../assets/img/hero-banner-bg-price.png) lightgray 50% / cover no-repeat;
}

.pricing__list {
  margin: 0 auto;
  padding: 0;
  padding-block-start: 24px;
  list-style: none;
  display: flex;
  gap: 24px;
  max-width: 981px;
}

.pricing__item {
  border-radius: 4px;
  border: 1px solid #14171E;
  box-shadow: 0px 5px 12px 0px rgba(20, 23, 30, 0.30);
  background-color: transparent;
  padding-block-start: 0;
  flex: 1;
  position: relative;
  transition: linear 0.3s;
  //padding: 20px;
  padding-block-end: 20px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  border-radius: 14px;
  border: 1px solid $main_color;
  box-shadow: 0px 5px 12px 0px rgba(20, 23, 30, 0.70);
}

.pricing__item:hover {
  transform: scale(1.01);
  transition: linear 0.3s;
}

.pricing__item:nth-child(2) {
  position: relative;
  background: linear-gradient(0deg, rgba(36, 36, 41, 0.90) 0%, rgba(36, 36, 41, 0.90) 100%),
              url(../../assets/img/pricing-card.png) lightgray 50% / cover no-repeat;

}

.pricing__item-title {
  width: 100.6%;
  inset-inline-start: -1px;
  //color: #fff;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  position: relative;
  //background-color: #d8d1d1;
  //background: linear-gradient(180deg, rgba(206, 206, 206, 0.10) 0%, #AD9D9D 100%);
  padding: 20px;
  border-radius: 14px 14px 0px 0px;
  border-top: 1px solid $main_color;
  border-right: 1px solid $main_color;
  border-left: 1px solid $main_color;
  background: linear-gradient(180deg, #2475E6 0%, #242429 100%);

}

//.pricing__item-title::after {
//  content: "";
//  display: block;
//  position: absolute;
//  width: 100%;
//  height: 1px;
//  background-color: tomato;
//  inset-block-end: -29px;
//}

.pricing__item-title-name {
  //color: #14171e;
}

.pricing__item-title-price {
  //color: #14171e;
  opacity: .7;
  font-weight: 400;
}

.pricing__price-list {
  margin: 0;
  padding: 0 20px;
  list-style: none;
}

.pricing__price-item {
  display: flex;
  column-gap: 8px;
  align-items: center;
  margin-block-end: 20px;
  //color: #fff;
}

.pricing__price-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}

.pricing-button {
  text-align: center;
  //border: 1px solid rgba(255, 255, 255, 0.15);
  //border-radius: 12px;
  //box-shadow: inset 0px 0px 14px 4px rgba(255, 255, 255, 0.25);
  //backdrop-filter: blur(14px);
  //background: rgba(255, 136, 14, 0.4);
  width: 90%;
  margin: 0 auto;
}

@media (max-width: 991px) {
  .pricing__list {
    flex-wrap: wrap;
    justify-content: center;
  }

  .pricing__item {
    width: 49%;
    flex: unset;
  }

  .pricing__item:nth-child(2) {
    inset-block-start: 0;
  }
}

@media (max-width: 767px) {
  .pricing__item {
    width: 59%;
  }
}

@media (max-width: 576px) {
  .pricing__item {
    width: 85%;
  }
}
